import React, { Component } from "react";
import "./Terms.scss";
import { Button } from '@material-ui/core';

class TermsAndConditions extends Component {

  constructor(props) {
    super(props);  
  }

  render() {

    const { userName } = this.props;
    const{Imei} =this.props;
    const{StoreName}=this.props;
    const{Manufacturer}=this.props;
    const{Model}=this.props;
    const{DisplayName}=this.props;
    const{Storage}=this.props;
    const{Serial}=this.props;
    const{Price}=this.props;
    const{Currency}=this.props;

    return (
      <div className="terms-wrapper">
        <p><b>Terms and Conditions</b></p>
        <p>PLEASE READ THESE TERMS AND CONDITIONS ACCESSING OR USING THE MOBILE APPLICATION (“APPLICATION”).</p>
        <p>BY INSTALLING. DOWNLOADING AND/OR USING THIS APPLICATION OR THE SERVICE. YOU CONFIRM THAT YOU HAVE READ. UNDERSTOOD AND AGREE TO THESE TERMS AND CONDITIONS AND SUCH AGREEMENT CREATES A BINDING CONTRACT BETWEEN YOU AND <b>BOLTTECH DEVICE PROTECTION (“BOLTTECH”)..</b></p>
        <p>IT IS UNDERSTOOD THAT UPON SUBMISSION OF THE REGISTRATION FORM, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS SET FORTH BELOW. IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS AND CONDITIONS. YOU MAY NOT ACCESS OR USE THIS APPLICATION. <b>BOLTTECH</b> MAY AT ANY TIME MODIFY THESE TERMS AND CONDITIONS. AND SUCH MODIFICATIONS SHALL BE EFFECTIVE IMMEDIATELY UPON POSTING OF THE MODIFIED TERMS AND CONDITIONS. YOU AGREE TO REVIEW THE TERMS AND CONDITIONS PERIODICALLY AND YOUR CONTINUED ACCESS OR USE OF THE APPLICATION SHALL BE DEEMED YOUR CONCLUSIVE ACCEPTANCE OF THE TERMS AND CONDITIONS. AS MAY BE MODIFIED BY <b>BOLTTECH</b> FROM TIME TO TIME. </p>
        <p>FOR PURPOSES OF THESE TERMS AND CONDITIONS, THE TERMS 'SUBSCRIBER", 'YOU", OR 'YOUR ' SHALL REFER TO YOU AS THE PERSON WHO AGREES TO BE BOUND BY THESE TERMS AND CONDITIONS. THE TERMS "<b>BOLTTECH</b>" "WE", OR "US" SHALL REFER TO BOLTTECH DEVICE PROTECTION.</p>
        <table>
         <tbody>
            <tr>
               <td>User Name:</td>
               <td>{ userName }</td>
            </tr>
            <tr>
               <td>Mobile Device Make & Model:</td>
             <td>{ "DisplayName : "+DisplayName +" , "+"Manufacturer : "+ Manufacturer+" , "+"Model : "+ Model+" , "+"Storage : " +Storage+" , "+"Serial-No : " +Serial}</td>
            </tr>
            <tr>
               <td>Mobile Device Purchase Price:</td>
              <td>{Price +" "+ Currency}</td>
           </tr>
           <tr>
              <td>Mobile Device IMEI:</td>
            <td>{Imei}</td>
           </tr>
           <tr>
               <td>Purchaser Name:</td>
             <td>{StoreName}</td>
          </tr>
          </tbody>
        </table>
        <br></br>
        <br></br>
        <p><b>1. ELIGIBILITY</b></p>
        <p>By using this service of BOLTTECH, you warrant that you are of legal age and not among those prohibited by law to enter into any contractual relationship.</p>
        <p><b>2. DISCLAIMER </b></p>
        <p><b>BOLTTECH</b> manages the Application and the buy back program (trade-in). The Purchaser listed is the ultimate buyer of the Mobile Device subject of the buy back and YOU, as the Seller, understands that BOLTTECH is merely a third-party administrator and that it disclaims any liability with respect to claims to be made by you against the Purchaser and vice versa.</p>
        
        <p><b>3. UNDERTAKING</b></p> 

        <p>You hereby agree to the following declaration:</p>
        <ul>
           <li>The Purchaser has offered to buy the Mobile Device stated above for the Purchase Price stated above.</li>
           <li>I acknowledge that I have agreed to sell the Mobile Device to the Purchaser for the Purchase Price stated above.</li>
           <li>I declare that I am the legal and beneficial owner of the Mobile Device and that the Mobile Device is not subject of any lien or encumbrance and therefore legally entitled to sell it to the Purchaser.</li>
           <li>By agreeing to sell the device to the Purchaser under the agreed Purchase Price and upon payment of the same in full, I acknowledge that the title and ownership over the Mobile Device is transferred to the Purchaser.</li>
           <li>I acknowledge that there is no refund policy for the Mobile Device, as well as for any content or accessories or equipment attached to the Mobile Device, and I will not request such refund from the Purchaser.</li>
           <li>I understand and hereby authorize the Purchaser to remove and destroy, including but not limited to, all memory cards, SIM cards, cases, covers and other accessories attached to or inserted in the Mobile Device. The Purchaser is not required to recover or return any such items to myself and I declare that I will not request such return to the Purchaser.</li>
           <li>I understand and hereby authorize the Purchaser to delete all personal content of whatever nature that may be stored on the Device, including but not limited to photos, videos, music, address books, text messages, e-mails, passwords and any and all personal data. The Purchaser will process any personal data in accordance with the provisions of Philippine Data Privacy Act of 2012 (‘Act’) and may transfer it for processing outside the territory of Philippines. The Purchaser is not required to recover or return any such content to myself, and I will not request such return to the Purchaser. The Purchaser may delete the personal information, without prior notice, in accordance with the Act.</li>
           <li>I acknowledge that I have read and fully understand the statements above and have signed this declaration without force. </li>
           <li>By submitting this Declaration form, I acknowledge the receipt of the Purchase Price.</li>
        </ul> 

        <p>The User further declares that all the above information submitted is true and correct and that <b>BOLTTECH</b> has the right to not accept any transaction or deny access to the Application if the user violates any of the foregoing declaration, without prejudice to the right of <b>BOLTTECH</b> to pursue any subsequent charges to claim damages for the above violations.</p>
        <p><b>4. APPLICABLE LAW AND JURISDICTION</b></p>
        <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of the Republic of the Philippines. All disputes arising from these Terms and Conditions, other agreements, and commitments, must be settled in a court of competent jurisdiction in Makati City, Metro Manila, Philippines. </p>
        <p><b>5. SEVERABILITY </b></p>
        <p>If any portion of these Terms and Conditions is found invalid or unenforceable under judicial decree or decision of a Court, the remainder of these Terms and Conditions shall remain valid and enforceable to the extent allowed according to their terms and the invalid clause shall be replaced by BOLTTECH with a clause which reflects the object of the original clause as close as possible.</p>
        <p><b>6. WAIVER  </b></p>
        <p>No failure or delay by <b>BOLTTECH</b> to exercise or enforce any of its rights, powers or remedies under these Terms and Conditions will act as a waiver of such rights, powers or remedies and no waiver of a breach in a particular situation shall be held to be a waiver of any other or subsequent breach and nor will any partial exercise preclude any further exercise of the same, or of some other right, power or remedy.</p>
        <p><b>7. FEEDBACK   </b></p>
        <p>The User agrees to provide any comments and suggestions regarding the performance of the Application on the e-mail address. Furthermore, User agrees to receive marketing campaigns and promotions from  <b>BOLTTECH. BOLTTECH</b> agrees to monitor this mailbox, but is under no obligation to implement any of the suggestions and/or proposals, or be required to respond to any questions asked in the e-mail/ s. <b>BOLTTECH</b> and/or its affiliates will be free to use any information the User provides, including comments, problem reports, suggestions or enhancement requests, to <b>BOLTTECH</b> for any purpose, subject to any applicable patents or copyrights.</p>
        <p><b>8. NOTICES   </b></p>
        <p>All notices in writing are required to be given to BOLTTECH must be addressed to legal@bolttech.io and no alteration in the terms and conditions herein mentioned will be held valid unless the same is signed or initialed by an authorized representative of BOLTTECH.</p>
        
        <p>If you agree to these Terms and Conditions, click the "I agree" button. By clicking said button, you also agree that these Terms and Conditions shall be binding upon your use of the Application</p>
        </div>
    );
  }

}

export default TermsAndConditions;
